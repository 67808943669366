.project-title {
    text-align: center;
}

.project-item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 20px;
    margin: 10px;
    margin-bottom: 60px;
    height: 50%;
}

.project-img {
    display: none;
}

.project-img img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.project-desc {
    position: relative;
    width: 100%;
    height: 100%;
    line-height: 200%;
    text-align: justify;
    border-left: 7px solid #4760A0;
    padding-left: 30px;
}

@media only screen and (min-width: 1100px) {
    .project-title {
        text-align: left;
    }

    .project-list {
        justify-content: center;
        padding: 30px 150px;
        background-color: whitesmoke;
    }
    
    /* .project-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        padding: 20px;
        margin: 10px;
        margin-bottom: 60px;
        height: 50%;
    } */
    
    .project-img {
        display: block;
        border-radius: 2%;
        width: 45%;
        height: 100%;
    }
    
    .project-img img {
        width: 80%;
    }
    
    .project-desc {
        position: relative;
        width: 45%;
        height: 100%;
        line-height: 200%;
        text-align: justify;
    }
    
    .project-link {
        position: absolute;
        right: 0;
    }
}