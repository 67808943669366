footer {
    position: relative;
    justify-items: bottom;
    height: 100%;
    background-color: #282c34;
    overflow: hidden;
    margin: 0;
    padding: 30px;
    color: white;
}

footer > ul.footer-menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}

footer > ul.footer-menu li {
    list-style: none;
}

footer hr {
    margin: 0;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.external-link {
    background-color: #282c34;
    font-size: calc(30px + 2vmin);
    color: white;
    padding: 0 5px;
}

.external-link:hover {
    color: #61dafb;
    transition: .2s ease-in-out;
}

.copyright {
    margin: 0;
    /* padding: 1em; */
    padding-top: 1em;
    text-align: center;
}

@media only screen and (max-width: 600px) {

}