.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  position: absolute;
  color: white;
  text-decoration: none;
  right: 0;
  top: 0;
  padding: 30px 40px;
  font-size: large;
}

.App-link:hover {
  color: #61dafb;
  transition: .2s ease-in-out;
}

.arrow-icon {
  vertical-align: bottom;
}

.me {
  border-radius: 50%;
  width: 40%;
  height: 20%;
}

.hero-title {
  margin: 40px 10px 10px 0;
  width: 80%;
  text-align: center;
}

.hero-subtitle {
  margin: 0 10px 40px 0;
  width: 60%;
  text-align: center;
}

.divider {
  color: #61dafb;
}

@media only screen and (min-width: 1100px) {
  .me {
    border-radius: 50%;
    width: 20%;
    height: 20%;
  }
}
